import React, { createContext, useCallback, useEffect, useState, useMemo } from 'react';
import ethPrice from 'eth-price';
import * as d3 from 'd3';
import axios from 'axios';
import { useConnectWallet } from '@web3-onboard/react';
import Resolution from '@unstoppabledomains/resolution';
import { BigNumber, ethers } from 'ethers';
import useSWR from 'swr';

import {
  JAY_CONTRACT_,
  ERC721_,
  BalanceChecker_,
  LP_STAKING_,
  JAY_MART_,
  REF_MART_,
  LP_TOKEN_,
  USDC_TOKEN_,
  JAY_DERIV_CONTRACT,
  DerivABI
} from './constants/contracts';

const WS_URL = 'wss://jaypeggers-backend.herokuapp.com/echo';

const INFURAKEY = '3f7945f3464d4e1e8e0f2b31a402ce60';
const AlchemyApiKey = '3AgF019UXqiHyZ62YAXQgWSw2q-XRFGs';

const network = 'homestead';
const fetcher = (library) => (args) => {
  const [...params] = args;
  return library([...params]);
};
// prettier-ignore
export const Web3Context = createContext({
  JAY_CONTRACT: JAY_CONTRACT_,
  ERC721: ERC721_,
  BalanceChecker: BalanceChecker_,
  LP_STAKING: LP_STAKING_,
  JAY_MART: JAY_MART_,
  REF_MART: REF_MART_,
  LP_TOKEN: LP_TOKEN_,
  USDC_TOKEN: USDC_TOKEN_,
  address: "",
  wallet: null,
  pendingRequest: false,
  leaderBoard: [],
  mojimon: [],
  connected: false,
  mintResult: [],
  approvedAddresses:[],
  setRef: async () => {},
  isValidRef: async () => {},
  claimRef: async () => {},
  getMyReferralLink: async () => {},
  handleConnect: async () => {},
  handleLogout: () => {},
  sell: async () => {},
  stake: async () => {},
  harvest: async () => {},
  unstake: async () => {},
  approveStake: async () => {},
  getTokenData: async () => {},
  getTokenDataDash: async () => {},
  buy: async () => {},
  approveBuy: async () => {},
  getFloor: async () => {},
  getLastSale: async () => {},
  approveERC20: async () => {},
  resetEventType: async () => {},
  buyJay: async () => {},
  buyNfts: async () => {},
  getEthToJay: async () => {},
  getLPTokenData: async () => {},
  closeFail: () => {},
  checkBalances: async () => {},
  checkApprovals: async () => {},
  getRefAddresses: async () => {},
  setLoading: async () => {},
  ref: '',
  myRef: '',
  myFees: 0,
  isRefSet: false,
  pageKey: null,
  amount: "0",
  loading: true,
  eth: 0,
  geth: "0",
  lpBalance: '0',
  staked: '0',
  rewards: '0',
  tlv: "0",
  gethToEth: "0",
  tvl: "0",
  ethBorrowed: "0",
  ens: "",
  price: "0",
  fail: false,
  succeed: false,
  succeedMsg: '',
  eventType: '',
  stakingApproved: false,
  buyApproved: false,
  provider: null,
  signer: null,
  chainId: 1,
  infuraProvider: ethers.getDefaultProvider(network, {
    etherscan: 'A7257M9X55EPEKRDUNV1Q4JH65YM7NKHIT',
    // alchemy: 'g1GPr8Zqbwwnnc14xhraypg1rWB-1Neq',
    alchemy: '3AgF019UXqiHyZ62YAXQgWSw2q-XRFGs', // main
    // pocket: '95f40ef6a91572c9d6fdfc4b2c74523519186ba6cead77c4a0a87c2dc7110a33'
    pocket: '569b9d450a6822d605df887c8ccb798c4605e0bf246944a34ef8d11d2d900365' // main
  }),
  jayContractSigned: null,
  jayMartSigned: null,
  refContract: null,
  lpContractSigned: null,
  lpTokenContractSigned: null,
  fees: [],
  loader: false,
  bought: [0,0],
  stakingApy: 0,
  ethprice: 0,
  lpPrice: 0,
  userNftbank: null,
  collections: null,
  vaultNftbank: null,
  vaultCollection: null,
  vaultOpensea: [],
  priceData: [],
  priceDataUSDC: []
});
/* eslint-disable react/prop-types */
export function Web3Provider({ children }) {
  // @ts-ignore
  const [state, setState] = useState({
    JAY_CONTRACT: JAY_CONTRACT_,
    ERC721: ERC721_,
    BalanceChecker: BalanceChecker_,
    LP_STAKING: LP_STAKING_,
    JAY_MART: JAY_MART_,
    REF_MART: REF_MART_,
    LP_TOKEN: LP_TOKEN_,
    USDC_TOKEN: USDC_TOKEN_,
    address: '',
    pageKey: null,
    wallet: null,
    pendingRequest: false,
    leaderBoard: [],
    mojimon: [],
    approvedAddresses: [],
    connected: false,
    mintResult: [],
    loading: true,
    disconnected: true,
    amount: '0',
    eth: 0,
    geth: '0',
    tlv: '0',
    lpBalance: '0',
    staked: '0',
    rewards: '0',
    gethToEth: '0',
    tvl: '',
    ethBorrowed: '0',
    price: '0',
    fail: false,
    succeed: false,
    succeedMsg: '',
    eventType: '',
    stakingApproved: false,
    buyApproved: false,
    provider: null,
    signer: null,
    chainId: 1,
    infuraProvider: ethers.getDefaultProvider(network, {
      etherscan: 'A7257M9X55EPEKRDUNV1Q4JH65YM7NKHIT',
      // alchemy: 'g1GPr8Zqbwwnnc14xhraypg1rWB-1Neq',
      alchemy: '3AgF019UXqiHyZ62YAXQgWSw2q-XRFGs', // main
      // pocket: '95f40ef6a91572c9d6fdfc4b2c74523519186ba6cead77c4a0a87c2dc7110a33'
      pocket: '569b9d450a6822d605df887c8ccb798c4605e0bf246944a34ef8d11d2d900365' // main
    }),
    jayContractSigned: null,
    jayMartSigned: null,
    refContract: null,
    lpContractSigned: null,
    lpTokenContractSigned: null,
    fees: [],
    loader: false,
    bought: [0, 0],
    stakingApy: 0,
    ethprice: 0,
    lpPrice: 0,
    userNftbank: null,
    collections: null,
    vaultNftbank: null,
    vaultCollection: null,
    vaultOpensea: [],
    priceData: [],
    priceDataUSDC: [],
    ref: '',
    myRef: '',
    myFees: 0,
    isRefSet: false
  });
  const {
    provider,
    signer,
    chainId,
    infuraProvider,
    jayContractSigned,
    jayMartSigned,
    refContract,
    lpContractSigned,
    lpTokenContractSigned,
    JAY_CONTRACT,
    ERC721,
    BalanceChecker,
    LP_STAKING,
    JAY_MART,
    REF_MART,
    LP_TOKEN,
    USDC_TOKEN,
    stakingApy
  } = state;
  const [{ wallet }, connect, disconnect] = useConnectWallet();
  const fetcher = (url) => axios.request(url).then((res) => res.data);

  const useMyERC20Addresses = () => {
    const options = {
      method: 'POST',
      url: `https://eth-mainnet.g.alchemy.com/v2/${AlchemyApiKey}`,
      headers: { accept: 'application/json', 'content-type': 'application/json' },
      data: {
        id: 1,
        jsonrpc: '2.0',
        method: 'alchemy_getTokenBalances',
        params: [state?.address, 'erc20']
      }
    };

    const { data, error } = useSWR(state.address !== '' ? options : undefined, fetcher);
    const _data = data?.result.tokenBalances.filter((d) => Number(d.tokenBalance) > 0) || undefined;
    console.log(_data);
    const options2 = {
      method: 'POST',
      url: `https://eth-mainnet.g.alchemy.com/v2/${AlchemyApiKey}`,
      headers: { accept: 'application/json', 'content-type': 'application/json' },
      data: {
        id: 1,
        jsonrpc: '2.0',
        method: 'alchemy_getTokenMetadata',
        params: _data?.map((c) => c.contractAddress).slice(0, 1) || undefined
      }
    };
    const { data: d } = useSWR(options2, fetcher);
    console.log(d);

    return d;
  };

  const da = useMyERC20Addresses();
  console.log(da);
  const usdcToken = useMemo(
    () =>
      new ethers.Contract(
        USDC_TOKEN[chainId].address,
        USDC_TOKEN[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      ),
    [chainId, USDC_TOKEN]
  );
  const lpToken = useMemo(
    () =>
      new ethers.Contract(
        LP_TOKEN[chainId].address,
        LP_TOKEN[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      ),
    [chainId, LP_TOKEN]
  );

  const jayContract = useMemo(
    () =>
      new ethers.Contract(
        JAY_CONTRACT[chainId].address,
        JAY_CONTRACT[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      ),
    [chainId, JAY_CONTRACT]
  );

  const lpContract = useMemo(
    () =>
      new ethers.Contract(
        LP_STAKING[chainId].address,
        LP_STAKING[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      ),
    [chainId, LP_STAKING]
  );

  const setLoading = useCallback((loader) => {
    setState((s) => ({ ...s, loader }));
  }, []);

  const closeFail = useCallback(() => {
    setState((s) => ({ ...s, fail: false, succeed: false, succeedMsg: '' }));
  }, []);

  const getPriceData = async (contract, lastBlock, newLastblock) => {
    const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);

    const _jayContract = new ethers.Contract(contract, JAY_CONTRACT[1].abi, _infuraProvider);

    const eventFilter = _jayContract.filters.Price();

    const events = await _jayContract.queryFilter(eventFilter, lastBlock, newLastblock);

    const arrays = [];
    events.forEach((o, i) => {
      // // // console.log(o.args.time)
      const date = Number(o.args.time) * 1000;
      // // // console.log(date)
      const sent = Number(ethers.utils.formatEther(o.args.sent))?.toFixed(8) || 0;
      // // // console.log(sent)
      const rec = Number(ethers.utils.formatEther(o.args.recieved))?.toFixed(8) || 1;
      // // // console.log(rec)
      const price = sent / rec;
      let oldPrice = price;
      if (i > 0) {
        const _sent = Number(ethers.utils.formatEther(events[i - 1].args.sent))?.toFixed(8) || 0;
        const _rec = Number(ethers.utils.formatEther(events[i - 1].args.recieved))?.toFixed(8) || 1;
        oldPrice = _sent / _rec;
      }
      arrays.push([date, oldPrice, price, oldPrice, price]);
      return null;
    });

    return arrays;
  };
  const getPriceDataUSDC = async (contract, lastBlock, newLastblock) =>
    /*
    const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);

    const _jayContract = new ethers.Contract(contract, DerivABI, _infuraProvider);

    const eventFilter = _jayContract.filters.Price();

    const events = await _jayContract.queryFilter(eventFilter, lastBlock, newLastblock);

    let arrays = [];
    if (events.length) {
      arrays = await Promise?.all(
        events.map(async (o, i) => {
          const block = await o.getBlock();
          const date = Number(block.timestamp) * 1000;
          // // // console.log(date)
          const sent = Number(ethers.utils.formatUnits(o.args.sent, 6))?.toFixed(8) || 0;
          // // // console.log(sent)
          const rec = Number(ethers.utils.formatEther(o.args.recieved))?.toFixed(8) || 1;
          // // // console.log(rec)
          const price = sent / rec;
          let oldPrice = price;
          if (i > 0) {
            const _sent =
              Number(ethers.utils.formatUnits(events[i - 1].args.sent, 6))?.toFixed(8) || 0;
            const _rec =
              Number(ethers.utils.formatEther(events[i - 1].args.recieved))?.toFixed(8) || 1;
            oldPrice = _sent / _rec;
          }
          return [date, oldPrice, price, oldPrice, price];
        })
      );
    }
    */
    [];
  useEffect(async () => {
    const price = await getPriceData(JAY_CONTRACT[1].address);
    const priceUSDC = await getPriceDataUSDC('0xca9f9671765F8D1A7e19ae2639E01FFF730f0D9B');
    setState((s) => ({ ...s, priceData: price, priceDataUSDC: priceUSDC }));
    return () => {};
  }, []);

  useEffect(() => {
    // // console.log('runner');
    // const ws = new WebSocket(WS_URL);
    const wsProvider = new ethers.providers.WebSocketProvider(
      `wss://mainnet.infura.io/ws/v3/${INFURAKEY}`,
      'mainnet'
    );
    const contract = new ethers.Contract(JAY_CONTRACT[1].address, JAY_CONTRACT[1].abi, wsProvider);
    contract.on('Price', (from, to, value, o) => {
      const date = Number(o.args.time) * 1000;
      // // // console.log(date)
      const sent = Number(ethers.utils.formatEther(o.args.sent))?.toFixed(8) || 0;
      // // // console.log(sent)
      const rec = Number(ethers.utils.formatEther(o.args.recieved))?.toFixed(8) || 1;
      // // // console.log(rec)
      const price = sent / rec;

      const getOldPrice = (priceData) => {
        const oldPrice = priceData[priceData.length - 1][2] || 0.001;

        return [...priceData, [date, oldPrice, price, oldPrice, price]];
      };
      setState((s) => ({
        ...s,
        priceData: getOldPrice(s.priceData)
      }));
    });
    const contract2 = new ethers.Contract(
      '0xca9f9671765F8D1A7e19ae2639E01FFF730f0D9B',
      DerivABI,
      wsProvider
    );
    contract2.on('Price', (from, to, value, o) => {
      // console.log('AAAAAAA');
      value.getBlock().then((block) => {
        const date = Number(block.timestamp) * 1000;
        // console.log(date);
        const sent = Number(ethers.utils.formatUnits(value.args.sent, 6))?.toFixed(8) || 0;
        // // // console.log(sent)
        const rec = Number(ethers.utils.formatEther(value.args.recieved))?.toFixed(8) || 1;
        // // // console.log(rec)
        const price = sent / rec;

        const getOldPrice = (priceDataUSDC) => {
          let oldPrice = priceDataUSDC[priceDataUSDC.length - 1][2];
          const oldPrice2 = priceDataUSDC[priceDataUSDC.length - 1][1];
          if (oldPrice < oldPrice2) oldPrice = oldPrice2;
          return [...priceDataUSDC, [date, oldPrice, price, oldPrice, price]];
        };

        setState((s) => ({
          ...s,
          priceDataUSDC: getOldPrice(s.priceDataUSDC)
        }));
      });
    });
    return () => {
      contract.removeAllListeners();
    };
  }, []);

  const getTokenDataDash = useCallback(
    async (tokenAddress, pairAddress, stakingAddress, rewardTokenAddress = 'native') => {
      let dexPrice = '0.00';
      try {
        // ETH Price
        const ethResponse = await axios.get(
          `https://api.dexscreener.com/latest/dex/pairs/ethereum/${pairAddress}`
        );
        dexPrice = ethResponse.data.pair.priceUsd;
        // console.log(ethResponse);
      } catch {
        // console.log('ouch');
      }
      const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);
      let decimals = 18;
      if (rewardTokenAddress !== 'native') {
        const rewardToken = new ethers.Contract(
          rewardTokenAddress,
          LP_TOKEN_[chainId].abi,
          new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
        );
        decimals = await rewardToken.decimals();
      }
      const stakingContract = new ethers.Contract(
        stakingAddress,
        LP_STAKING_[chainId].abi,
        _infuraProvider
      );
      const token = new ethers.Contract(tokenAddress, LP_TOKEN_[chainId].abi, _infuraProvider);
      const rewardToken =
        rewardTokenAddress === 'native'
          ? null
          : new ethers.Contract(rewardTokenAddress, LP_TOKEN_[chainId].abi, _infuraProvider);
      const _vaultBal =
        rewardTokenAddress === 'native'
          ? await _infuraProvider.getBalance(tokenAddress)
          : await rewardToken.balanceOf(tokenAddress);
      let _apy = 0;
      try {
        _apy =
          rewardTokenAddress === 'native'
            ? stakingApy
            : Number(ethers.utils.formatUnits(await stakingContract.getAPY())).toFixed(2);
      } catch {
        // console.log('darn');
      }

      let backingPrice = '0.00';

      let jaytoeth = 0;
      let ethtojay = 0;
      if (rewardTokenAddress === 'native') {
        jaytoeth = Number(
          ethers.utils.formatEther(await token.JAYtoETH(ethers.utils.parseEther('1')))
        );
        ethtojay = ethers.utils.formatEther(await token.ETHtoJAY(ethers.utils.parseEther('1')));
        backingPrice = Number(state.ethprice);
      } else {
        jaytoeth = Number(
          ethers.utils.formatUnits(await token.JAYtoETH(ethers.utils.parseEther('1')), decimals)
        );
        ethtojay = ethers.utils.formatEther(
          await token.ETHtoJAY(ethers.utils.parseUnits(Number(jaytoeth / 0.9).toFixed(6), decimals))
        );
        try {
          // ETH Price
          const ethResponse = await axios.get(
            `https://api.dexscreener.com/latest/dex/tokens/${rewardTokenAddress}`
          );
          backingPrice = ethResponse.data.pairs.find(
            (d) => d.baseToken.address === rewardTokenAddress && d.dexId === 'uniswap'
          ).priceUsd;
          console.log(ethResponse);
        } catch {
          // console.log('ouch');
        }
      }
      return {
        balance: state?.address
          ? ethers.utils.formatEther(await token.balanceOf(state.address))
          : '0',
        apy: _apy.toString(),
        vaultBal: ethers.utils.formatUnits(_vaultBal, decimals),
        dexPrice,
        sellPrice: (Number(jaytoeth) * Number(backingPrice) * 0.9).toFixed(2),
        buyPrice: ((Number(jaytoeth) * Number(backingPrice)) / 0.955).toFixed(2)
      };
    },
    [chainId, state?.address, LP_TOKEN_, stakingApy, state?.ethprice]
  );
  const setRef = useCallback(async (ref) => {
    if (ref !== '') {
      const _refContract = new ethers.Contract(
        REF_MART[chainId].address,
        REF_MART[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      );
      if (await _refContract.isValidRef(ref))
        setState((s) => ({
          ...s,
          ref
        }));
    }
  }, []);

  const getMyReferralLink = useCallback(async () => {
    // console.log(1);
    if (state?.address.indexOf('0x') === 0) {
      const _refContract = new ethers.Contract(
        REF_MART[chainId].address,
        REF_MART[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      );
      const _myRef = await _refContract.getMyRef(state?.address);
      const _myFees = await _refContract.getRefEarnedTotal(state?.address);
      const _isRefSet = await _refContract.isRefSet(state?.address);
      // console.log(_myRef);
      if (_myRef !== '') {
        setState((s) => ({
          ...s,
          myRef: _myRef,
          isRefSet: _isRefSet,
          myFees: ethers.utils.formatEther(_myFees)
        }));
      }
    }
  }, [state?.address]);
  const isValidRef = async (ref) => {
    if (ref !== '') {
      const _refContract = new ethers.Contract(
        REF_MART[chainId].address,
        REF_MART[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      );
      const _isValid = await _refContract.isValidRef(ref);
      return !_isValid;
    }
  };

  const claimRef = useCallback(
    async (myRef) => {
      setState((s) => ({ ...s, pendingRequest: true }));

      // console.log(myRef);
      if (myRef !== '') {
        if (await (await refContract.setRef(myRef)).wait())
          setState((s) => ({
            ...s,
            myRef,
            succeed: true,
            eventType: 'TAX',
            succeedMsg: `Use my referral link to save 5%25 on NFT tax-loss harvesting on @jaypeggerz https://app.jaypeggers.com/${myRef}`,
            pendingRequest: false
          }));
      }
    },
    [refContract]
  );
  const getTokenData = useCallback(
    async (tokenAddress, pairAddress) => {
      const token = new ethers.Contract(
        tokenAddress,
        LP_TOKEN_[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      );
      const pairContract =
        pairAddress === 'native'
          ? null
          : new ethers.Contract(
              pairAddress,
              LP_TOKEN_[chainId].abi,
              new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
            );

      const __balance = token.balanceOf(state.address);
      const __amountApproved =
        pairAddress === 'native'
          ? ethers.constants.MaxUint256
          : pairContract.allowance(state.address, tokenAddress);
      let __balancePair;

      if (pairAddress === 'native') {
        const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);
        __balancePair = _infuraProvider.getBalance(state.address);
      } else __balancePair = pairContract.balanceOf(state.address);

      const __decimals = pairAddress === 'native' ? 18 : pairContract.decimals();

      const [_balance, _amountApproved, _balancePair, _decimals] = await Promise.all([
        __balance,
        __amountApproved,
        __balancePair,
        __decimals
      ]);
      // console.log(ethers.utils.formatUnits(_amountApproved, _decimals));
      return {
        balance: ethers.utils.formatUnits(_balance),
        amountApproved: ethers.utils.formatUnits(_amountApproved, _decimals),
        balancePair: ethers.utils.formatUnits(_balancePair, _decimals)
      };
    },
    [chainId, state?.address]
  );
  const getLPTokenData = useCallback(
    async (tokenAddress, stakingAddress, rewardTokenAddress = 'native') => {
      const stakingContract = new ethers.Contract(
        stakingAddress,
        LP_STAKING_[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      );
      if (state?.address) {
        const token = new ethers.Contract(
          tokenAddress,
          LP_TOKEN_[chainId].abi,
          new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
        );
        let decimals = 18;
        if (rewardTokenAddress !== 'native') {
          const rewardToken = new ethers.Contract(
            rewardTokenAddress,
            LP_TOKEN_[chainId].abi,
            new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
          );
          decimals = await rewardToken.decimals();
        }

        let lpPrice = 0;
        try {
          // ETH Price
          const ethResponse = await axios.get(
            `https://api.dexscreener.com/latest/dex/pairs/ethereum/${tokenAddress}`
          );
          lpPrice = ethResponse.data.pair.liquidity.usd;
          // console.log(ethResponse);
        } catch {
          // console.log('ouch');
        }

        const __balance = token.balanceOf(state.address);
        const __totalSupply = token.totalSupply();
        const __amountApproved = token.allowance(state.address, stakingAddress);
        const __staked = stakingContract.getStaked(state.address);
        const __rewards = stakingContract.getReward(state.address);

        let _apy = 0;
        try {
          _apy = rewardTokenAddress === 'native' ? stakingApy : await stakingContract.getAPY();
        } catch {
          // console.log('darn');
        }
        // console.log(_apy);
        const [_staked, _balance, _amountApproved, _rewards, _totalSupply] = await Promise.all([
          __staked,
          __balance,
          __amountApproved,
          __rewards,
          __totalSupply
        ]);
        return {
          balance: ethers.utils.formatEther(_balance),
          amountApproved: ethers.utils.formatEther(_amountApproved),
          staked: ethers.utils.formatEther(_staked),
          stakedUSDValue: (
            (Number(ethers.utils.formatEther(_staked)) * lpPrice) /
            Number(ethers.utils.formatEther(_totalSupply))
          ).toFixed(2),
          lpUSDValue: (
            (Number(ethers.utils.formatEther(_balance)) * lpPrice) /
            Number(ethers.utils.formatEther(_totalSupply))
          ).toFixed(2),
          apy: rewardTokenAddress === 'native' ? _apy : ethers.utils.formatEther(_apy),
          rewards: ethers.utils.formatUnits(_rewards, decimals)
        };
      }

      const _apy = rewardTokenAddress === 'native' ? stakingApy : await stakingContract.getAPY();
      // console.log(_apy);
      return {
        balance: '0',
        amountApproved: '0',
        staked: '0',
        stakedUSDValue: '0',
        lpUSDValue: '0',
        apy: ethers.utils.formatEther(_apy),
        rewards: '0'
      };
    },
    [chainId, state?.address, LP_TOKEN_, stakingApy]
  );

  const getValues = useCallback(async () => {
    const _address = state.address;

    let ud = '';
    try {
      const resolution = Resolution.fromEthersProvider(provider);
      ud = await resolution.reverse(_address);
      if (ud?.length > 0) {
        const searchModule = document.querySelector('onboard-v2');
        const searchModuleRoot = searchModule && searchModule.shadowRoot;
        const title = searchModuleRoot.querySelector('.address');
        // // console.log(title.clientWidth);
        const maxwidth = Math.floor(title.clientWidth / 16);
        const ensConcat = ud?.length > maxwidth ? ud.substring(0, 11).concat('..') : ud;
        title.innerHTML = ensConcat;
      }
    } catch {
      ud = '';
    }
    const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);
    const _eth = _infuraProvider.getBalance(_address);
    const _geth = jayContract.balanceOf(_address);
    const _buyApproved = jayContract.allowance(_address, JAY_MART[chainId].address);
    const [eth, geth, buyApproved] = await Promise.all([_eth, _geth, _buyApproved]);
    const net = await provider.getNetwork();

    setState((s) => ({
      ...s,
      buyApproved: ethers.utils.formatEther(buyApproved),
      eth: ethers.utils.formatEther(eth),
      geth: ethers.utils.formatEther(geth),
      chainId: net.chainId,
      ens: ud
    }));
  }, [state?.address, infuraProvider, jayContract, provider]);

  const getAlchemyCollections = useCallback(async () => {
    const vaultOpensea = (await axios.get('https://jaypeggers-backend.herokuapp.com/vault')).data;

    const FIVE_MIN = 5 * 60 * 1000;
    if (
      new Date() - new Date(vaultOpensea.lastUpdate) > FIVE_MIN &&
      vaultOpensea.running === 'done'
    ) {
      console.log('run update on vault');
      try {
        const data = await axios.post('https://jaypeggers-backend.herokuapp.com/vault');
        setState((s) => ({
          ...s,
          vaultOpensea: data.collections
        }));
      } catch (e) {
        setState((s) => ({
          ...s,
          vaultOpensea: vaultOpensea.collections
        }));
      }
    }
    setState((s) => ({
      ...s,
      vaultOpensea: vaultOpensea.collections
    }));
  }, []);

  const days = (date1, date2) => {
    const difference = date1.getTime() - date2.getTime();
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  const getVault = useCallback(async () => {
    const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);
    const _eth2 = _infuraProvider.getBalance(JAY_CONTRACT[chainId].address);
    const _ethprice = ethPrice('usd');
    const _jayContractSigned = new ethers.Contract(
      JAY_MART[chainId].address,
      JAY_MART[chainId].abi,
      _infuraProvider
    );
    const _rewardPerTokenStored = lpContract.getRewardPerTokenStored();
    const _usdcBal = usdcToken.balanceOf(LP_TOKEN[chainId].address);
    const _totalLP = lpToken.totalSupply();
    const _fees = _jayContractSigned.getFees();
    const _bought = _jayContractSigned.getTotals();
    const _vaultBal = _infuraProvider.getBalance(jayContract.address);

    const [eth2, ethprice, fee, bough, rewardPerTokenStored, usdcBal, totalLP, vaultBal] =
      await Promise.all([
        _eth2,
        _ethprice,
        _fees,
        _bought,
        _rewardPerTokenStored,
        _usdcBal,
        _totalLP,
        _vaultBal
      ]);
    const bought = bough.map((b) => Number(b));
    // // // console.log(bought);
    const fees = fee.map((b) => Number(ethers.utils.formatEther(b)));
    const tlv = Number(ethprice[0].substring(5)) * Number(ethers.utils.formatEther(eth2));

    const usdValueOfToken =
      ethers.utils.formatEther(usdcBal.mul('2000000000000')) / ethers.utils.formatEther(totalLP);

    const _apy =
      (Number(ethprice[0].substring(5)) * ethers.utils.formatEther(rewardPerTokenStored)) /
      usdValueOfToken;

    const apy =
      usdValueOfToken > 0
        ? ((_apy / days(new Date(), new Date('4/20/2023'))) * 36500).toFixed(2).toString()
        : '0.00';
    // // console.log(apy);> 0

    setState((s) => ({
      ...s,
      tlv,
      lpPrice: usdValueOfToken,
      ethprice: Number(ethprice[0].substring(5)),
      fees,
      vaultBal: ethers.utils.formatEther(vaultBal),
      stakingApy: apy,
      bought: [bough[0].toString(), bought.toString()]
    }));
  }, [infuraProvider, chainId]);

  const checkBalances = useCallback(async (owner, addresses, ids, erc721) => {
    if (addresses.length > 0) {
      const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);
      const _balanceChecker = new ethers.Contract(
        BalanceChecker[1].address,
        BalanceChecker[1].abi,
        _infuraProvider
      );
      const bals = await _balanceChecker.getBalances(owner, addresses, ids, erc721);
      return bals.map((b) => Number(b));
    }
    return [];
  }, []);
  const checkApprovals = useCallback(
    async (owner, addresses, erc721) => {
      const _erc721 = [];
      const martAddress =
        state.ref === '' && !state.isRefSet ? JAY_MART[chainId].address : REF_MART[chainId].address;
      function onlyUnique(value, index, self) {
        if (self.indexOf(value) === index) {
          _erc721.push(erc721[index]);
          return true;
        }
        return false;
      }
      if (addresses.length > 0) {
        const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);
        const _balanceChecker = new ethers.Contract(
          BalanceChecker[chainId].address,
          BalanceChecker[chainId].abi,
          _infuraProvider
        );

        const duplicates = addresses.filter(onlyUnique);

        const bals = await _balanceChecker.getApprovals(owner, martAddress, duplicates, _erc721);
        const approvals = {};
        duplicates.forEach((a, i) => {
          approvals[a] = bals[i];
        });
        return approvals;
      }
      return {};
    },
    [state.ref, state.isRefSet]
  );

  const getRefAddresses = async () => {
    // console.log(1);
    const _provider = new ethers.providers.EtherscanProvider(
      network,
      'A7257M9X55EPEKRDUNV1Q4JH65YM7NKHIT'
    );
    const _refMartContract = new ethers.Contract(
      REF_MART[chainId].address,
      REF_MART[chainId].abi,
      _provider
    );
    const history = await _provider.getHistory(REF_MART[chainId].address, '18685925');

    const decoded = (val) => {
      try {
        const decode = ethers.utils.defaultAbiCoder.decode(
          ['string'],
          ethers.utils.hexDataSlice(val, 4)
        );
        // console.log(decode.length > 0 ? decode[0] : null);
        return decode[0].length > 0 ? decode[0] : null;
      } catch {
        return null;
      }
    };

    const _history = history.filter((f) => typeof decoded(f.data) === 'string');
    const leaderBoard = await Promise?.all(
      _history.map(async (address) => {
        const val = await _refMartContract.getRefEarnedTotal(address.from);
        const name = await _refMartContract.getMyRef(address.from);
        return { address: address.from, value: ethers.utils.formatEther(val), name };
      })
    );
    return leaderBoard.sort((a, b) => (Number(a.value) < Number(b.value) ? 1 : -1)).concat(0, 8);
  };
  useEffect(() => {
    // const bn = ethers.utils.parseEther(val.toString());

    if ((state.isRefSet || state.ref.length > 0) && state.fees.length === 4) {
      const _refMartContract = new ethers.Contract(
        REF_MART[chainId].address,
        REF_MART[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      );
      _refMartContract.getFees().then((f) => {
        const fees = f.map((b) => Number(ethers.utils.formatEther(b)));
        setState((s) => ({
          ...s,
          fees: [fees[0], s.fees[1], s.fees[2], s.fees[3], 0]
        }));
      });
    }
  }, [state.isRefSet, state.ref, state.fees]);
  const getEthToJay = useCallback(
    async (type, _address, _pair, val) => {
      // @ts-ignore
      const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);

      const _jayContract = new ethers.Contract(
        _address,
        JAY_CONTRACT[chainId].abi,
        _infuraProvider
      );
      const pairContract = new ethers.Contract(
        _pair,
        LP_TOKEN_[chainId].abi,
        new ethers.providers.AlchemyProvider(network, AlchemyApiKey)
      );
      const decimals = _pair === 'native' ? 18 : await pairContract.decimals();
      // console.log(decimals);
      const bn = ethers.utils.parseUnits(val.toString(), type === 'BUY' ? decimals : 18);

      const _val =
        type === 'BUY'
          ? ethers.utils.formatUnits(await _jayContract.ETHtoJAY(bn))
          : ethers.utils.formatUnits(await _jayContract.JAYtoETH(bn), decimals);

      return (_val * 0.9)?.toFixed(8) || 0;
    },
    [chainId]
  );

  const resetEventType = () => {
    setState((s) => ({
      ...s,
      eventType: ''
    }));
  };
  // mutators
  const approveERC20 = useCallback(
    async (pair, contract) => {
      try {
        setState((s) => ({ ...s, pendingRequest: true }));

        const provider = new ethers.providers.Web3Provider(wallet?.provider, 'any');
        const signer = provider.getSigner();
        const jayContractSigned = new ethers.Contract(
          pair,
          JAY_CONTRACT[chainId].abi,
          provider
        ).connect(signer);
        await (await jayContractSigned.approve(contract, ethers.constants.MaxUint256)).wait();
        setState((s) => ({
          ...s,
          succeed: true,
          eventType: 'APPROVE',
          succeedMsg: '',
          pendingRequest: false
        }));
      } catch (error) {
        setState((s) => ({ ...s, pendingRequest: false, fail: true }));
      }
    },
    [JAY_CONTRACT, chainId, wallet]
  );
  const approve = useCallback(
    async (contract) => {
      const martAddress =
        state.ref === '' && !state.isRefSet ? JAY_MART[chainId].address : REF_MART[chainId].address;
      if (state.approvedAddresses.find((a) => a === contract)) return true;
      // // console.log('ay');
      const nftContract = new ethers.Contract(contract, ERC721[chainId].abi, provider).connect(
        signer
      );
      // // console.log(jayMartSigned.address);
      if (await nftContract.isApprovedForAll(state.address, martAddress)) {
        setState((s) => ({ ...s, approvedAddresses: [contract, ...state.approvedAddresses] }));
        return true;
      }
      try {
        await (
          await nftContract.setApprovalForAll(martAddress, true, {
            gasLimit: '70000'
          })
        ).wait();
        setState((s) => ({ ...s, approvedAddresses: [contract, ...state.approvedAddresses] }));
        return true;
      } catch {
        return false;
      }
    },
    [state.approvedAddresses, state?.address, provider, chainId, signer, state.ref, state.isRefSet]
  );
  const getLastSale = useCallback(async (address, tokenId) => {
    const _infuraProvider = new ethers.providers.AlchemyProvider(network, AlchemyApiKey);
    const apiKey = '3AgF019UXqiHyZ62YAXQgWSw2q-XRFGs';

    const _tokenId = BigInt(tokenId).toString();
    const oldData = JSON.parse(localStorage.getItem(`LASTSALE-${address + _tokenId}`));
    if (oldData?.set) {
      return oldData;
    }
    const url = `https://eth-mainnet.g.alchemy.com/nft/v2/${apiKey}/getNFTSales?contractAddress=${address}&fromBlock=0&toBlock=latest&order=desc&tokenId=${_tokenId}&limit=1`;
    const data = await axios.get(url);
    if (data?.data?.nftSales.length > 0) {
      const block = data?.data?.nftSales[0]?.blockNumber;
      const _block = await _infuraProvider.getBlock(block);

      const timestamp = new Date(_block.timestamp * 1000);
      const dateFormat = `${timestamp.getDate()}-${
        timestamp.getMonth() + 1
      }-${timestamp.getFullYear()}`;

      const _ethprice = (
        await axios.get(
          `https://api.coingecko.com/api/v3/coins/ethereum/history?date=${dateFormat}`
        )
      )?.data?.market_data?.current_price?.usd;
      const price =
        ethers.utils.formatEther(data?.data?.nftSales[0]?.sellerFee?.amount || 0) * _ethprice;

      const ret = {
        set: true,
        lastSale: price
      };
      localStorage.setItem(`LASTSALE-${address + _tokenId}`, JSON.stringify(ret));
      return ret;
    }
    const ret = {
      set: true,
      lastSale: '0'
    };
    localStorage.setItem(`LASTSALE-${address + _tokenId}`, JSON.stringify(ret));
    return ret;

    // const url = `https://api.diadata.org/v1/NFTVolume/Ethereum/${address}?starttime=${start.getTime()}&endtime=${end.getTime()}`;
  }, []);
  const getFloor = useCallback(async (address) => {
    const date = new Date();
    const FIVE_MIN = 5 * 60 * 1000;
    const oldData = JSON.parse(localStorage.getItem(`FLOOR-${address}`));
    if (date - new Date(oldData?.time) < FIVE_MIN) {
      return oldData;
    }

    const end = new Date();
    // const start = subHours(end, 24);
    const apiKey = '3AgF019UXqiHyZ62YAXQgWSw2q-XRFGs';
    const url = `https://eth-mainnet.g.alchemy.com/nft/v2/${apiKey}/getFloorPrice?contractAddress=${address}`;
    const data = await axios.get(url);
    const ret = {
      time: date.toISOString(),
      floor: data?.data?.openSea?.floorPrice || ''
    };
    localStorage.setItem(`FLOOR-${address}`, JSON.stringify(ret));

    return ret;

    // const url = `https://api.diadata.org/v1/NFTVolume/Ethereum/${address}?starttime=${start.getTime()}&endtime=${end.getTime()}`;
  }, []);

  const buy = useCallback(
    async (_address, _pairAddress, quan, native = false) => {
      try {
        // open modal
        // toggle pending request indicator
        setState((s) => ({ ...s, pendingRequest: true }));
        const provider = new ethers.providers.Web3Provider(wallet?.provider, 'any');
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          _address,
          native ? JAY_CONTRACT[chainId].abi : JAY_DERIV_CONTRACT[chainId].abi,
          provider
        ).connect(signer);
        const pair = new ethers.Contract(_pairAddress, JAY_CONTRACT[chainId].abi, provider).connect(
          signer
        );
        const decimals = native ? 18 : await pair.decimals();
        // send transaction
        const _quan = ethers.utils.parseUnits(quan, decimals);

        // // console.log('DOG');
        if (native) {
          await (await contract.buy(state.address, { value: _quan })).wait();
        } else {
          await (await contract.buy(state.address, _quan)).wait();
        }
        setState((s) => ({
          ...s,
          succeed: true,
          eventType: 'SWAP',
          succeedMsg: '',
          pendingRequest: false
        }));
      } catch (error) {
        setState((s) => ({ ...s, pendingRequest: false, fail: true }));
      }
    },
    [wallet, state.address, JAY_CONTRACT, chainId]
  );
  const approveBuy = useCallback(async () => {
    try {
      setState((s) => ({ ...s, pendingRequest: true }));

      await (
        await jayContractSigned.approve(JAY_MART[chainId].address, ethers.constants.MaxUint256)
      ).wait();
      setState((s) => ({
        ...s,
        succeed: true,
        eventType: 'APPROVE',
        succeedMsg: '',
        pendingRequest: false,
        buyApproved: true
      }));
    } catch (error) {
      setState((s) => ({ ...s, pendingRequest: false, fail: true }));
      return null; // tslint:disable-line
    }
  }, [lpTokenContractSigned]);
  const approveStake = useCallback(
    async (pairAddress, stakingAddress) => {
      try {
        setState((s) => ({ ...s, pendingRequest: true }));
        const provider = new ethers.providers.Web3Provider(wallet?.provider, 'any');
        const signer = provider.getSigner();
        const lpTokenContract = new ethers.Contract(
          pairAddress,
          LP_TOKEN[chainId].abi,
          provider
        ).connect(signer);
        await (await lpTokenContract.approve(stakingAddress, ethers.constants.MaxUint256)).wait();
        const _stakeApproved = await lpTokenContract.allowance(
          wallet.accounts[0].address,
          stakingAddress
        );

        setState((s) => ({
          ...s,
          succeed: true,
          eventType: 'APPROVE',
          succeedMsg: '',
          pendingRequest: false,
          stakingApproved: ethers.utils.formatEther(_stakeApproved)
        }));
      } catch (error) {
        setState((s) => ({ ...s, pendingRequest: false, fail: true }));
        return null; // tslint:disable-line
      }
    },
    [wallet, LP_TOKEN, chainId]
  );
  const stake = useCallback(
    async (address, quan) => {
      try {
        const provider = new ethers.providers.Web3Provider(wallet?.provider, 'any');
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          address,
          LP_STAKING[chainId].abi,
          [chainId].abi,
          provider
        ).connect(signer);
        // open modal
        // toggle pending request indicator
        setState((s) => ({ ...s, pendingRequest: true }));

        // send transaction
        // console.log('send');
        const _quan = ethers.utils.parseUnits(
          quan.toString().indexOf('.') === 0 ? `0${quan.toString()}` : quan.toString(),
          'ether'
        );
        if (Number(_quan) > 0) {
          await (await contract.deposit(_quan, { gasLimit: '250000' })).wait();
          setState((s) => ({
            ...s,
            succeed: true,
            eventType: 'STAKE',
            succeedMsg: '',
            pendingRequest: false
          }));
        } else {
          setState((s) => ({ ...s, pendingRequest: false, fail: true }));
          return null; // tslint:disable-line
        }

        // @ts-ignore
        return quan;
      } catch (error) {
        setState((s) => ({ ...s, pendingRequest: false, fail: true }));
        return null; // tslint:disable-line
      }
    },
    [wallet]
  );
  const harvest = useCallback(
    async (address) => {
      try {
        const provider = new ethers.providers.Web3Provider(wallet?.provider, 'any');
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          address,
          LP_STAKING[chainId].abi,
          [chainId].abi,
          provider
        ).connect(signer);
        // open modal
        // toggle pending request indicator
        setState((s) => ({ ...s, pendingRequest: true }));

        // send transaction

        await (await contract.deposit('0', { gasLimit: '250000' })).wait();
        setState((s) => ({
          ...s,
          succeed: true,
          eventType: 'STAKE',
          succeedMsg: '',
          pendingRequest: false
        }));

        // @ts-ignore
        return '0';
      } catch (error) {
        setState((s) => ({ ...s, pendingRequest: false, fail: true }));
        return null; // tslint:disable-line
      }
    },
    [wallet]
  );
  const unstake = useCallback(
    async (address, quan) => {
      try {
        const provider = new ethers.providers.Web3Provider(wallet?.provider, 'any');
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          address,
          LP_STAKING[chainId].abi,
          [chainId].abi,
          provider
        ).connect(signer);
        // open modal
        // toggle pending request indicator
        setState((s) => ({ ...s, pendingRequest: true }));
        // send transaction
        const _quan = ethers.utils.parseUnits(
          quan.toString().indexOf('.') === 0 ? `0${quan.toString()}` : quan.toString(),
          'ether'
        );

        await (await contract.withdraw(_quan, { gasLimit: '250000' })).wait();
        setState((s) => ({
          ...s,
          succeed: true,
          eventType: 'STAKE',
          succeedMsg: '',
          pendingRequest: false
        }));

        // @ts-ignore
        return quan;
      } catch (error) {
        setState((s) => ({ ...s, pendingRequest: false, fail: true }));
        return null; // tslint:disable-line
      }
    },
    [wallet]
  );
  const sell = useCallback(
    async (_address, quan) => {
      try {
        // open modal
        // toggle pending request indicator
        setState((s) => ({ ...s, pendingRequest: true }));
        const provider = new ethers.providers.Web3Provider(wallet?.provider, 'any');
        const signer = provider.getSigner();
        const contract = new ethers.Contract(_address, JAY_CONTRACT[chainId].abi, provider).connect(
          signer
        );

        // send transaction
        const _quan = ethers.utils.parseEther(quan);

        await (await contract.sell(_quan)).wait();
        setState((s) => ({
          ...s,
          succeed: true,
          eventType: 'SWAP',
          succeedMsg: '',
          pendingRequest: false
        }));
      } catch (error) {
        setState((s) => ({ ...s, pendingRequest: false, fail: true }));
      }
    },
    [wallet, JAY_CONTRACT, chainId]
  );
  const buyJay = useCallback(
    async (assets, quan) => {
      try {
        // open modal
        // toggle pending request indicator
        setState((s) => ({ ...s, pendingRequest: true }));

        // console.log(quan);
        // send transaction
        const _jay = ethers.utils.parseUnits(quan.toString(), 'ether');
        const jayVal = await getEthToJay(
          'BUY',
          JAY_CONTRACT_[1].address,
          'native',
          quan.toString()
        );
        if (state.ref === '' && !state.isRefSet) {
          await (
            await jayMartSigned.buyJay(
              assets.erc721_address,
              assets.erc721_id,
              assets.erc1155_address,
              assets.erc1155_id,
              assets.erc1155_count,
              { value: _jay }
            )
          ).wait();
        } else {
          await (
            await refContract.buyJay(
              assets.erc721_address,
              assets.erc721_id,
              assets.erc1155_address,
              assets.erc1155_id,
              assets.erc1155_count,
              state.ref,
              { value: _jay }
            )
          ).wait();
        }

        setState((s) => ({
          ...s,
          succeed: true,
          eventType: 'TAX',
          succeedMsg: `I sold ${
            assets.erc721_address.length + assets.erc1155_address.length
          } NFTs to the @jaypeggerz Vault for ${(Number(jayVal) / 2).toFixed(
            6
          )} $JAY on https://app.jaypeggers.com/${state.myRef}`,
          pendingRequest: false
        }));

        // @ts-ignore
        return true;
      } catch (error) {
        // console.log(error);
        setState((s) => ({ ...s, pendingRequest: false, fail: true }));
        return false; // tslint:disable-line
      }
    },
    [jayMartSigned, getEthToJay, JAY_CONTRACT_, state.ref, state.isRefSet, state.myRef]
  );
  const buyNfts = useCallback(
    async (assets, quan) => {
      try {
        setState((s) => ({ ...s, pendingRequest: true }));

        const _quan = ethers.utils.parseEther(quan.toString());
        // send transaction
        await (
          await jayMartSigned.buyNFTs(
            assets.erc721_address,
            assets.erc721_id,
            assets.erc1155_address,
            assets.erc1155_id,
            assets.erc1155_count,
            { value: _quan }
          )
        ).wait();
        setState((s) => ({
          ...s,
          succeed: true,
          eventType: 'BUYBACK',
          succeedMsg: '',
          pendingRequest: false
        }));

        // @ts-ignore
        return true;
      } catch (error) {
        setState((s) => ({ ...s, pendingRequest: false, fail: true }));
        return false; // tslint:disable-line
      }
    },
    [jayMartSigned]
  );
  // useffects
  useEffect(() => {
    getAlchemyCollections();
  }, []);

  const handleLogout = useCallback(async () => {
    await disconnect();
    window.localStorage.setItem('connectedWallets', '');
    setState((state) => ({
      ...state,
      wallet: null,
      signer: null,
      address: '',
      connected: false
    }));
  }, [disconnect]);

  const handleConnect = useCallback(
    async ({ auto = false }) => {
      const previouslyConnectedWallets = window.localStorage.getItem('connectedWallets');
      let wallets = [];

      if (previouslyConnectedWallets != null && previouslyConnectedWallets !== '') {
        // You can also auto connect "silently" and disable all onboard modals to avoid them flashing on page load
        try {
          wallets = await connect({
            autoSelect: { label: JSON.parse(previouslyConnectedWallets)[0], disableModals: true }
          });
        } catch {
          window.localStorage.setItem('connectedWallets', '');
        }

        // OR - loop through and initiate connection for all previously connected wallets
        // note: This UX might not be great as the user may need to login to each wallet one after the other
        // for (walletLabel in previouslyConnectedWallets) {
        //   await onboard.connectWallet({ autoSelect: walletLabel })
        // }
      } else if (!auto && wallets.length === 0) {
        wallets = await connect();
      }
      if (wallets.length) {
        const wallet = wallets[0];
        const connectedWallets = wallets.map(({ label }) => label);
        window.localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets));
        const provider = new ethers.providers.Web3Provider(wallet?.provider, 'any');
        const signer = provider.getSigner();
        const jayContractSigned = new ethers.Contract(
          JAY_CONTRACT[chainId].address,
          JAY_CONTRACT[chainId].abi,
          provider
        ).connect(signer);

        const refContract = new ethers.Contract(
          REF_MART[chainId].address,
          REF_MART[chainId].abi,
          provider
        ).connect(signer);

        const jayMartSigned = new ethers.Contract(
          JAY_MART[chainId].address,
          JAY_MART[chainId].abi,
          provider
        ).connect(signer);

        const lpContractSigned = new ethers.Contract(
          LP_STAKING[chainId].address,
          LP_STAKING[chainId].abi,
          [chainId].abi,
          provider
        ).connect(signer);

        const lpTokenContractSigned = new ethers.Contract(
          LP_TOKEN[chainId].address,
          LP_TOKEN[chainId].abi,
          [chainId].abi,
          provider
        ).connect(signer);

        /* const token = await Web3Token.sign(async (msg) => signer.signMessage(msg), '1d');

        // attaching token to axios authorization header
        const user = await axios.post(
          'http://localhost:8080/users',
          { name: 'Adam' },
          {
            headers: {
              Authorization: token
            }
          }
        );

        // // console.log(user.data.user._id);
        const user2 = await axios.get(
          `http://localhost:8080/users/${user.data.user.address}`,
          { address: user.data.user.address },
          {
            headers: {
              Authorization: token
            }
          }
        );

        // // console.log(user2); */

        wallet?.provider.on('network', (newNetwork, oldNetwork) => {
          // When a Provider makes its initial connection, it emits a "network"
          // event with a null oldNetwork along with the newNetwork. So, if the
          // oldNetwork exists, it represents a changing network
          if (oldNetwork) {
            window.location.reload();
          }
        });

        try {
          /* eslint no-undef: "error" */
          twq('event', 'tw-oen7w-oen8m', {
            conversion_id: wallets[0].accounts[0].address
          });
        } catch {
          // console.log('');
        }
        try {
          /* eslint no-undef: "error" */
          twq('event', 'tw-oen7w-oen89', {
            conversion_id: wallets[0].accounts[0].address
          });
        } catch {
          // console.log('');
        }
        setState((state) => ({
          ...state,
          wallet,
          provider,
          jayContractSigned,
          jayMartSigned,
          refContract,
          lpContractSigned,
          lpTokenContractSigned,
          signer,
          address: wallets[0].accounts[0].address,
          connected: true,
          disconnected: false,
          pendingRequest: false
        }));
      }
    },
    [connect, chainId]
  );
  const walletAddressNew = wallet?.accounts[0]?.address;
  useEffect(() => {
    // console.log('WELCOME JAYPEGGOOOOOR');
    if (!state.connected && !wallet) {
      handleConnect({ auto: true });
    } else if (!state.connected) {
      handleConnect({ auto: false });
    } else if (state.connected && !wallet) {
      window.localStorage.setItem('connectedWallets', '');
      setState((s) => ({
        ...s,
        connected: false,
        wallet: null,
        signer: null,
        address: '',
        amount: '0',
        eth: 0,
        geth: '0',
        ens: ''
      }));
    } else if (state.address !== wallet?.accounts[0]?.address) {
      setState((s) => ({
        ...s,
        address: wallet?.accounts[0]?.address
      }));
    }
  }, [wallet, state?.connected, handleConnect, walletAddressNew, state?.address]);

  useEffect(() => {
    if (state.address !== '' || state.succeed) getValues();
  }, [state.address, getValues, state.succeed]);

  useEffect(() => {
    if (infuraProvider !== null && state.succeed === false) getVault();
  }, [infuraProvider, getVault, state.succeed]);

  /* useEffect(() => {
    if (Number(state.vaultBal > 0)) {
      getRefAddresses().then((_leaderboard) => {
        setState((s) => ({ ...s, leaderboard: _leaderboard }));
      });
    
  }, [getRefAddresses, state.vaultBal]);

  useEffect(() => {
    if (
      (state.address !== '' && typeof state.address !== 'undefined' && state.address !== null) ||
      state.succeed
    )
      getNftBank();
  }, [state.address, getNftBank, state.succeed]); */

  return (
    // @ts-ignore
    <Web3Context.Provider
      // @ts-ignore
      value={useMemo(
        () => ({
          ...state,
          setState,
          handleConnect,
          sell,
          stake,
          harvest,
          unstake,
          buy,
          approveBuy,
          approveStake,
          getTokenData,
          getTokenDataDash,
          getFloor,
          getLastSale,
          approveERC20,
          resetEventType,
          buyJay,
          buyNfts,
          getEthToJay,
          getLPTokenData,
          approve,
          closeFail,
          setLoading,
          checkBalances,
          checkApprovals,
          getRefAddresses,
          handleLogout,
          setRef,
          isValidRef,
          claimRef,
          getMyReferralLink
        }),
        [
          state,
          setState,
          handleConnect,
          sell,
          buy,
          approveBuy,
          stake,
          harvest,
          approveStake,
          getTokenData,
          getTokenDataDash,
          unstake,
          getFloor,
          getLastSale,
          approveERC20,
          buyJay,
          buyNfts,
          getEthToJay,
          getLPTokenData,
          approve,
          closeFail,
          setLoading,
          checkBalances,
          checkApprovals,
          getRefAddresses,
          handleLogout,
          setRef,
          isValidRef,
          claimRef,
          getMyReferralLink
        ]
      )}
    >
      {children}
    </Web3Context.Provider>
  );
}
